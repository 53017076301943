import { useEffect, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import type { ProductNanoCompositePublic } from '@centrito/api/nest/shopper/catalog/products/domain/composites'
import { GetProductsFilterType } from '@centrito/api/shared/enums'
import { trpc } from '@centrito/app/api/trpc'
import { SUBHOME_OPTION_GROUP } from '@centrito/app/features/shopper/home/constants'
import { SubhomeKey } from '@centrito/app/features/shopper/home/context/types'
import { useDispatch, useSelector } from '@centrito/app/store'
import {
  selectAnonymousUserId,
  selectSessionProductIds,
  selectUserId,
} from '@centrito/app/store/selectors'
import { actions } from '@centrito/app/store/slices/userData'
import { OUTLETS } from '@centrito/app/utils/helpers'
import { PRODUCT_LIST_TAB_FILTER } from './utils/constants'

const useInfiniteScrollSubhome = (): {
  products: ProductNanoCompositePublic[] | undefined
  loadMoreProducts: () => void
  productsCount: number | undefined
  isLoading: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  hasNextPage: boolean | undefined
} => {
  const router = useRouter()
  const userId = useSelector(selectUserId)
  const sessionProductIds = useSelector(selectSessionProductIds)
  const sessionProductIdsRef = useRef(sessionProductIds)
  const dispatch = useDispatch()

  const { tab, sub_tab } = router.query
  let f = {}
  if (tab && typeof tab === 'string') {
    const tabIntValue = parseInt(tab)
    const filterValue = SUBHOME_OPTION_GROUP[tabIntValue].href
    if (tabIntValue !== 0) {
      if (filterValue === SubhomeKey.MAIN) {
        f = {}
      } else if (filterValue === SubhomeKey.OUTLETS) {
        f = {
          [GetProductsFilterType.SUPPLIER]: OUTLETS.filter((outlet) => outlet.isActive).map(
            (outlet) => outlet.id,
          ),
        }
      } else {
        f = { [GetProductsFilterType.CATEGORY_PREFIX]: filterValue }
      }
    } else if (sub_tab && typeof sub_tab === 'string') {
      const tabIntValue = parseInt(sub_tab)
      const filterValue = PRODUCT_LIST_TAB_FILTER[tabIntValue]
      f = filterValue === '' ? {} : { [GetProductsFilterType.LIST_ID]: filterValue }
    }
  } else if (sub_tab && typeof sub_tab === 'string') {
    const tabIntValue = parseInt(sub_tab)
    const filterValue = PRODUCT_LIST_TAB_FILTER[tabIntValue]
    f = filterValue === '' ? {} : { [GetProductsFilterType.LIST_ID]: filterValue }
  }

  const filters = f

  const anonymousUserId = useSelector(selectAnonymousUserId)
  const stableQueryInput = useMemo(
    () => ({
      filters,
      anonymousUserId,
      userId,
    }),
    [filters, anonymousUserId, userId],
  )

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    trpc.catalog.product.findMany.useInfiniteQuery(
      {
        filters,
        anonymousUserId,
        userId,
        sessionProductIds: sessionProductIdsRef.current.map((product) => product.id),
      },
      {
        queryKeyHashFn: (input) => {
          // The input is an array where the second element contains our query parameters
          const queryParams = ((input[1] as any).input || input[1]) as typeof stableQueryInput

          // Create a stable hash that doesn't include sessionProductIds
          return JSON.stringify({
            filters: queryParams.filters,
            anonymousUserId: queryParams.anonymousUserId,
            userId: queryParams.userId,
          })
        },
        getNextPageParam: (lastPage, allPages) => {
          const loadedCount = allPages.flatMap((page) => page.products).length
          const count = lastPage.count
          // Check if the last page has any products
          return loadedCount <= count && lastPage.products.length > 0
            ? lastPage.nextCursor
            : undefined
        },
      },
    )

  // Update the ref when sessionProductIds changes
  useEffect(() => {
    sessionProductIdsRef.current = sessionProductIds
  }, [sessionProductIds])

  const loadMoreProducts = (): void => {
    if (
      hasNextPage &&
      !isFetchingNextPage &&
      (data?.pages[data.pages.length - 1]?.products?.length ?? 0) > 0
    ) {
      fetchNextPage()
      dispatch(actions.clearSessionProductIds())
    }
  }

  const products = data?.pages.flatMap((page) => page.products)
  const productsCount = data?.pages[0].count

  return {
    products,
    loadMoreProducts,
    productsCount,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
  }
}

export default useInfiniteScrollSubhome
