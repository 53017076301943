import { SubhomeKey } from '@centrito/app/features/shopper/home/context/types'

export const SUBHOME_OPTION_GROUP = [
  { name: 'Todo', href: SubhomeKey.MAIN },
  { name: 'OUTLETS', href: SubhomeKey.OUTLETS },
  { name: '🎄 Navidad 🎄', href: SubhomeKey.NAVIDAD },
  { name: '💄 Belleza', href: SubhomeKey.BELLEZA },
  { name: '👗 Mujer', href: SubhomeKey.MUJER },
  { name: '🍳 Cocina', href: SubhomeKey.COCINA },
  { name: '🐶 Mascotas', href: SubhomeKey.MASCOTAS },
  { name: '🎀 Accesorios de Belleza', href: SubhomeKey.ACCESORIOS_DE_BELLEZA },
  { name: '⚽ Niño', href: SubhomeKey.NINO },
  { name: '📚 Papelería', href: SubhomeKey.PAPELERIA },
  { name: '🌸 Niña', href: SubhomeKey.NINA },
  { name: '👔 Hombre', href: SubhomeKey.HOMBRE },
  { name: '🏠 Hogar', href: SubhomeKey.HOGAR },
  { name: '🎉 Piñateria', href: SubhomeKey.PIÑATERIA },
  { name: '🧸 Juguetes', href: SubhomeKey.JUGUETES },
  { name: '💍 Joyería y Accesorios', href: SubhomeKey.JOYERIA_Y_ACCESORIOS },
  { name: '👞 Calzado ', href: SubhomeKey.CALZADO },
  { name: '🫧 Aseo', href: SubhomeKey.ASEO },
  { name: '📲 Accesorios Electrónicos', href: SubhomeKey.ACCESORIOS_ELECTRONICOS },
  { name: '🏋‍♀ Suplementos Deportivos', href: SubhomeKey.SUPLEMENTOS_DEPORTIVOS },
  { name: '🍼 Maternidad y bebés', href: SubhomeKey.MATERNIDAD },
  { name: '🥊 Accesorios Deportivos', href: SubhomeKey.ACCESORIOS_DEPORTIVOS },
  { name: '🎮 Gaming', href: SubhomeKey.GAMING },
  { name: '🏍️ Motos', href: SubhomeKey.MOTOS },
  { name: '🔥 Adultos', href: SubhomeKey.ADULTOS },
]
