import React from 'react'
import isNil from 'lodash.isnil'
import FeedProductsGroup from '@centrito/app/components/Feed/ProductGroup'
import useDeepLinkFilters from '@centrito/app/utils/hooks/useDeepLinkFilters'
import useInfiniteScrollSubhome from '@centrito/app/utils/hooks/useInfiniteScrollSubhome'
import { FeedType } from '@centrito/app/utils/services/analytics/posthog/utils/FeedType'
import FeedViewEmpty from '@centrito/ui/src/components/FeedViewEmpty'
import FeedProductsGroupSkeleton from '@centrito/ui/src/components/skeletons/ProductGroupSkeleton'
import SubhomeSkeleton from '@centrito/ui/src/components/skeletons/SubhomeSkeleton'

export interface SubhomeFeedProps {
  isShowingFilters?: boolean
  header?: JSX.Element
  subhome?: string
  isMainHome?: boolean
  backgroundColor?: string
}

const SubhomeFeed: React.FC<SubhomeFeedProps> = ({
  isShowingFilters = true,
  header = undefined,
  subhome = undefined,
  isMainHome = false,
  backgroundColor = undefined,
}): JSX.Element => {
  const {
    products,
    hasNextPage,
    loadMoreProducts,
    isLoading,
    isFetching,
    isFetchingNextPage,
    productsCount,
  } = useInfiniteScrollSubhome(subhome)
  useDeepLinkFilters()

  if (isLoading || (isFetching && !isFetchingNextPage)) {
    if (isNil(subhome)) return <FeedProductsGroupSkeleton isShowingFilters={isShowingFilters} />
    else return <SubhomeSkeleton />
  }

  if (isNil(products) || products.length === 0) {
    if (!isLoading && !isFetching && productsCount === 0) {
      return <FeedViewEmpty />
    }
    if (isNil(subhome) || isMainHome) {
      return <FeedProductsGroupSkeleton isShowingFilters={isShowingFilters} />
    }
    return <SubhomeSkeleton />
  }

  return (
    <FeedProductsGroup
      products={products}
      hasMoreProducts={hasNextPage || false}
      loadMoreProducts={loadMoreProducts}
      feedType={isMainHome ? FeedType.HOME : FeedType.SUBHOME}
      header={header}
      isShowingFilters={false}
      productsCount={productsCount}
      backgroundColor={backgroundColor}
    />
  )
}

export default SubhomeFeed
